import Layout from '../utils/layout'
import React from 'react'
import SEO from '../utils/seo'

const NotFoundPage = props => {
  const data = {
    name: 'Page Not Found',
    slug: {
      current: '404',
    },
  }
  return (
    <Layout data={data}>
      <SEO title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
